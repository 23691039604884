.Blogs{
    margin: 80px 0;
}

.BlogList h1,
.Blogs h1{
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 2px;
}

.BlogList .blog_none,
.Blogs .blog_none{
    padding-top: 50px;
    text-align: center;
}

.BlogList .list,
.Blogs .list{
    min-height: calc(100vh - 500px);
}

.SearchBanner{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: black;
    position: fixed;
    bottom: -1px;
}

.SearchBanner p{
    display: block;
    height: 40px;
    width: 50%;
    margin: 0 auto;
    color: yellow;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 35px;
    border: 1px solid yellow;
}

@media screen and (min-width: 1000px){
    .BlogList{
        margin: 0 auto;
    }

    .Blogs{
        margin: 80px auto;
    }

    .BlogList .list{
        width: 750px;
    }

    .BlogList .blog_lost_contents{
        display: flex;
        width: 1000px;
        margin: 0 auto;
    }

    .BlogList .blog_search_banner{
        width: 200px;
    }

    .BlogList .blog_search_banner .SearchBanner{
        display: block;
        width: 100%;
        height: auto;
        background-color: white;
        border: 1px solid #008080;
        box-shadow: 2px 2px 2px #afeeee;
        border-radius: 5px;
        position: relative;
    }

    .BlogList .blog_search_banner .SearchBanner:hover{
        cursor: pointer;
    }

    .BlogList .blog_search_banner .SearchBanner p{
        display: block;
        height: 40px;
        width: 50%;
        margin: 0 auto;
        color: black;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        line-height: 35px;
        border: none;
    }
}
