*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border-width: 0;
}

body{
    display: flex;
	flex-direction: column;
    width: 100%;
	min-height: 100vh;
    margin: 0;
}

.Contents_Container{
    min-height: 640px;
    padding: 50px 0;
}

.ErrorPages h1{
    text-align: start;
}

@media screen and (min-width: 1000px){
    .Contents_Container{
        width: 1000px;
        min-height: 1200px;
        margin: 0 auto;
    }

    .ErrorPages h1{
        text-align: center;
    }
}