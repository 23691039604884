.fc .fc-toolbar.fc-header-toolbar
{
    display: block;
}

.fc .fc-toolbar-title
{
    text-align: center;
}

.fc .fc-button-primary
{
    height: 30px;
    width: 90px;
    line-height: 15px;
}

.fc, .fc *, .fc ::after, .fc ::before
{
    text-align: center;
}

.fc .fc-button .fc-icon
{
    font-size: 1em;
}

.fc-view-harness.fc-view-harness-active
{
    height: 665px !important;
}

.fc .fc-daygrid-day-frame
{
    height: 105px;
    z-index: 10;
}

.fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
    font-size: 12px;
}

.help_modal{
    display: block;
    width: 320px;
    position: fixed;
    top: 30%;
    left: calc((100% - 320px) / 2);
    border-radius: 5px;
    background-color: cyan;
    padding: 20px;
    z-index: 2;
}

.help_modal.hidden{
    display: none;
}

.help_modal h6{
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    color: #008b8b;
}

.overray{
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, .5);
}

.overray.hidden{
    display: none;
}

.help_modal_btn {
    display: block;
    width: 200px;
    margin: 0 auto;
}

.help_modal_btn.hidden
{
    display: none;
}

.help_modal_btn.input,
.help_modal_btn.list,
.help_modal_btn.close
{
    display: block;
    border: 1px solid blue;
    border-radius: 5px;
    margin: 5px auto;
    text-align: center;
    height: 40px;
    line-height: 40px;
    background-color: whitesmoke;
}

.help_modal_btn.input.hidden,
.help_modal_btn.list.hidden
{
    display: none;
}

.help_modal_inner.input,
.help_modal_inner.list,
.help_modal_inner.sum
{
    display: block;
}

.help_modal_inner.input.hidden,
.help_modal_inner.list.hidden,
.help_modal_inner.sum.hidden
{
    display: none;
}

.help_modal_inner.input select
{
    width: 200px;
    display: block;
    margin: 5px auto;
    height: 40px;
    text-align: center;
    border: 1px solid green;
    border-radius: 4px;
}

.help_modal_inner.input button
{
    margin: 10px auto;
    width: 200px;
}

.help_modal_inner.list ul
{
    margin: 15px 0;
    max-height: 250px;
    overflow: scroll;
}

.help_modal_inner.list ul li
{
    display: flex;
    height: 35px;
    background-color: snow;
    line-height: 35px;
    padding: 0 30px;
    margin-bottom: 10px;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
}

.help_modal_inner.list ul li .handel_delete
{
    display: inline-block;
    height: 100%;
    width: 100%;
    background-color: pink;
    position: absolute;
    top: -40px;
    left: 0;
    opacity: 0;
}

.trip_start,
.trip_end
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.trip_start_date,
.trip_end_date
{
    width: 130px;
    font-size: 14px;
    border-radius: 4px;
}

.help_modal_sum_list ul
{
    list-style: none;
}

.help_modal_sum_list ul li
{
    height: 35px;
    background-color: snow;
    line-height: 35px;
    padding: 0 30px;
    justify-content: space-between;
    border-radius: 10px;
    display: flex;
    width: 200px;
    margin: 5px auto;
}

.help_sum_btn
{
    display: block;
    width: 250px;
    height: 40px;
    margin: 50px auto;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    background-color: blue;
}