.info h1{
    margin: 30px 0;
    text-align: center;
    letter-spacing: 2px;
}

.info .list{
    margin: 50px auto;
}

.info .list h2{
    padding: 0 10px;
    margin-bottom: 30px;
    text-align: center;
}

.info .list p{
    display: block;
    padding: 0 20px;
    margin: 15px 0;
    font-size: 18px;
}

.info .list ul{
    margin-left: 50px;
}

.info .enactment{
    margin-left: 20px;
}

.info .enactment h6{
    font-size: 18px;
}

.info .enactment p{
    font-size: 16px;
}

@media screen and (min-width: 700px){
    .info .list{
        max-width: 700px;
    }

    .info .enactment {
        margin: 0 auto;
        padding-left: 20px;
        max-width: 700px;
    }
}