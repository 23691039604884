.Profile h1{
    margin: 30px 0;
    text-align: center;
    letter-spacing: 2px;
}

.Profile img{
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border-radius: 50%;
}

.Profile article{
    display: block;
    width: 300px;
    margin: 50px auto;
}

.Profile h3{
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #008b8b;
}

.Profile ul{
    padding: 10px;
}

.Profile .career li,
.Profile .area li{
    margin: 5px 0;
    font-size: 20px;
    list-style: none;
}

.Profile .comment p{
    margin: 10px;
    line-height: 30px;
    font-size: 20px;
}

@media screen and (min-width: 600px){
    .Profile article{
        width: 500px;
    }
}

@media screen and (min-width: 1000px){
    .Profile{
        margin: 0 auto;
    }
}
