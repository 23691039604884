.form_title{
    margin-top: 100px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 26px;
    letter-spacing: 2px;
}

form{
    padding: 0 20px;
}

form h2{
    font-size: 22px;
    text-align: center;
}

.form_label{
    padding: 10px;
    width: 100%;
}

.form_label.select{
    position: relative;
}

.form_label label{
    display: block;
    margin-bottom: 5px;
    font-size: 18px;
    letter-spacing: 2px;
}

.form_input,
.form_select,
.form_label .select_default,
.form_text{
    padding: 5px 10px;
    width: 100%;
    font-size: 20px;
    color: black;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form_input,
.form_select,
.form_label .select_default{
    height: 40px;
}

.form_text{
    height: 200px;
}

.form_select{
    z-index: 1;
}

.form_input.init{
    color: #ccc;
}

.form_select.init{
    color: rgba(0,0,0,0);
    font-size: 20px;
    position: absolute;
    opacity: 0;
}

.form_label .select_default{
    color: #808080;
    font-size: 20px;
    z-index: 0;
}

.form_label .select_default.hidden{
    display: none;
}

.formError{
    height: 30px;
    font-size: 18px;
    color: red;
}

.login_err{
    visibility: visible;
    height: 30px;
    font-size: 18px;
    color: red;
    text-align: center;
}

.login_err.hidden{
    visibility: hidden;
}

.view_images{
    width: 320px;
    height: 240px;
    margin: 0 auto;
    position: relative;
}

.view_images img{
    width: 100%;
    height: 100%;
    position: absolute;
}

.form_image{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}

.image_delete_btn{
    display: block;
    width: 40px;
    height: 40px;
    font-size: 30px;
    color: white;
    text-align: center;
    line-height: 35px;
    border: 1px solid white;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.image_delete_btn.hidden{
    display: none;
}

button{
    display: block;
    width: 250px;
    height: 40px;
    margin: 40px auto;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #00008b;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #ccc;
}

button:disabled{
    background-color: #ccc;
}

@media screen and (min-width: 600px){
    form{
        width: 500px;
        margin: 0 auto;
    }

    .view_images {
        width: 400px;
        height: 300px;
    }
}