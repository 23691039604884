.In_Category_Blog_Cassette_Container{
    height: 400px;
}

.In_Category_Blog_Cassette{
    display: block;
    width: 300px;
    height: 350px;
    margin: 0 auto;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-decoration: none;
}

.In_Category_Blog_Cassette .in_blog_title{
    display: block;
    height: 95px;
    padding: 5px;
    color: black;
}

.In_Category_Blog_Cassette .in_blog_attached{
    display: flex;
    align-items: center;
    width: 280px;
    height: 50px;
    padding: 10px;
}

.In_Category_Blog_Cassette .in_blog_attached .in_blog_date_icon{
    display: block;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
}

.In_Category_Blog_Cassette .in_blog_attached .in_blog_date{
    margin: 0 0 0 10px;
    padding: 0;
    font-size: 18px;
    color: black;
}

.In_Blog_Cassette .in_blog_desc{
    display: block;
    height: 190px;
    color: black;
    font-size: 18px;
}