.Btns{
    display: block;
    width: 250px;
    height: 40px;
    margin: 50px auto;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
}

.Btns p{
    letter-spacing: 2px;
}