.BlogDetail{
    padding-left: 10px;
    padding-right: 10px;
}

.BlogDetail .date{
    display: flex;
    flex-direction: column;
}

.BlogDetail .insert_date,
.BlogDetail .updated_date{
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 10px;
}

.BlogDetail .insert_date{
    margin: 0;
}

.BlogDetail .updated_date{
    margin: 0 0 30px;
}

.BlogDetail .insert_date:before,
.BlogDetail .updated_date:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
}

.BlogDetail .insert_date:before{
    border-bottom: 3px solid #ffd700;
}

.BlogDetail .updated_date:before{
    border-bottom: 3px solid #ff6347;
}

.BlogDetail .insert_date:after,
.BlogDetail .updated_date:after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 70px;
}

.BlogDetail .insert_date:after{
    border-bottom: 3px solid #007db9;
}

.BlogDetail .updated_date:after{
    border-bottom: 3px solid #007db9;
}

.BlogDetail .insert_date p,
.BlogDetail .updated_date p{
    font-size: 18px;
}

.BlogDetail .insert_date h6,
.BlogDetail .updated_date h6{
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
}

.BlogDetail h1{
    display: block;
    margin: 0 auto;
    padding: 0 5px;
    font-size: 24px;
}

.BlogDetail img{
    display: block;
    width: 320px;
    height: 240px;
    margin: 30px auto;
}

.BlogDetail .outline{
    width: 300px;
    margin: 50px auto;
}

.BlogDetail .outline li{
    margin: 20px 0;
    letter-spacing: .5px;
}

.BlogDetail .post_contents .post_content{
    display: block;
    padding: 20px 0;
    position: relative;
}

.BlogDetail .post_contents .post_content h3{
    display: block;
    margin-bottom: 30px;
    padding: 5px;
    font-size: 20px;
    color: white;
    background-color: #008080;
    border-radius: 5px;
}

.BlogDetail .post_contents .post_content p{
    display: block;
    padding: 0 10px;
    font-size: 20px;
    text-align: justify;
    white-space: pre-wrap;
    word-break: break-all;
}

.BlogDetail .post_contents .post_content:nth-child(even) p{
    display: block;
    padding: 0 10px;
    margin-left: auto;
    font-size: 20px;
    margin-left: auto;
}

.BlogDetail .reference{
    margin: 50px 0;
    padding: 0 30px;
}

.BlogDetail .reference li{
    margin: 10px 0;
}

@media screen and (min-width: 600px){
    .BlogDetail .date{
        display: flex;
        flex-direction: row;
    }
    
    .BlogDetail .insert_date{
        margin-left: 50px;
        margin-bottom: 30px;
    }

    .BlogDetail img{
        width: 720px;
        height: 540px;
    }

    .BlogDetail .outline{
        width: auto;
    }

    .BlogDetail .outline ol{
        display: grid;
        grid-template-columns: repeat(auto-fit, 330px);
        grid-gap: 20px;
        justify-content: center;
    }

    .BlogDetail .outline ol li{
        margin-left: 40px;
    }

    .BlogDetail .post_contents .post_content{
        width: 650px;
        margin: 50px auto;
    }

    .BlogDetail .post_contents .post_content img{
        width: 600px;
        height: 450px;
    }
}


@media screen and (min-width: 1000px){
    .BlogDetail{
        width: 1000px;
        margin: 0 auto;
    }

    .BlogDetail h1{
        width: 750px;
    }

    .BlogDetail .post_contents .post_content{
        width: 850px;
    }

    .BlogDetail .post_contents .post_content h3{
        text-align: center;
    }
}
