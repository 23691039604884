.OwnerContacts{
    margin: 80px auto;
}

.OwnerContacts h1{
    margin-bottom: 30px;
    text-align: center;
}

.OwnerContacts .contacts{
    margin: 30px 20px;
}

.OwnerContacts .contact p,
.OwnerContacts .contact a{
    display: block;
    font-size: 18px;
    margin: 5px 0 5px 10px;
    line-height: 25px;
}

.OwnerContacts .contact{
    width: 350px;
    margin: 30px auto;
    padding: 10px;
    list-style: none;
    background-color: #ffa500;
    border-radius: 5px;
}

.OwnerContacts .contact_default_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.OwnerContacts .date{
    width: 180px;
}

.OwnerContacts .label{
    display: block;
    width: 60px;
    border: 1px solid;
    text-align: center;
    background-color: #00ff00;
}

.OwnerContacts .contact_tap{
    height: 50px;
    width: 50px;
    position: relative;
}

.OwnerContacts .contact_tap .first{
    display: block;
    width: 50%;
    height: 2px;
    background-color: white;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 5px;
}

.OwnerContacts .contact_tap.up .first{
    transform: rotate(135deg);
    top: 50%;
    left: 5px;
}

.OwnerContacts .contact_tap .second{
    display: block;
    width: 50%;
    height: 2px;
    background-color: white;
    transform: rotate(135deg);
    position: absolute;
    top: 50%;
    right: 3px;
}

.OwnerContacts .contact_tap.up .second{
    transform: rotate(45deg);
    top: 50%;
    right: 3px;
}

.OwnerContacts .contact_all{
    display: block;
}

.OwnerContacts .contact_all.hidden{
    display: none;
}

.OwnerContacts .ower_text h3{
    color: #00ff00;
}