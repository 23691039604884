.Modal{
    display: block;
    width: 320px;
    position: fixed;
    top: 20%;
    left: calc((100% - 320px) / 2);
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    z-index: 2;
}

.Modal.hidden{
    display: none;
}

.Modal h6{
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    color: #008b8b;
}

.Modal ul{
    display: grid;
    grid-template-columns: repeat(auto-fit, 110px);
    grid-gap: 10px 30px;
    justify-content: center;
}

.Modal .modal_list{
    list-style: none;
    display: block;
    height: 80px;
    width: 110px;
}

.Modal .modal_list a{
    display: block;
    height: 50px;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    color: white;
    background-color: rgb(0, 0, 128);
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #ccc;
    
}

.Modal .modal_list p{
    margin: 5px 0 0;
    text-align: center;
}

.Modal .modal_close{
    width: 250px;
    height: 40px;
    margin: 10px auto;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    color: white;
    background-color: red;
    border-radius: 5px;
    text-decoration: none;
}

.overray{
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, .5);
}

.overray.hidden{
    display: none;
}