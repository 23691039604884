.Top_main{
    max-width: 1000px;
    margin: 0 auto;
}

.top_image{
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    background-position: 80% 0;
    background-repeat: no-repeat;
    background-size: cover;
    visibility: visible;
    transition: opacity;
    transition-duration: 1s;
    opacity: 1;
}

.contents_container{
    padding: 50px 0;
}

.contents_title{
    padding-left: 30px;
    padding-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #008b8b;
}