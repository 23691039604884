.TopScroll{
    display: block;
    width: 50px;
    height: 50px;
    font-size: 40px;
    text-align: center;
    border-radius : 50%;
    border : 1px solid red;
    box-shadow : 2px 2px 2px #ccc;
    position : fixed;
    bottom : 100px;
    right : 20px;
    z-index: 1;
    opacity: 1;
    transform: translateY(0);
    transition: all;
    transition-duration: 1s;
}

.TopScroll.hidden{
    transform: translateY(300);
    position: absolute;
    top: 1000px;
    opacity: 0;
}

@media screen and (min-height: 1000px){
    .TopScroll.hidden{
        top: 1200px;
    }
}