.Footer{
    margin-top: auto;
    padding: 30px 20px 80px;
    width: 100%;
    font-size: 20px;
    color: white;
    background-color: #001000;
}

.footer_menu_list{
    margin: 0 auto;
    width: 250px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    list-style: none;
    opacity: 1;
    transform: translateY(0);
    transition: all;
    transition-duration: 1s;
}

.footer_menu_list a {
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
}

.footer_menu_list.hidden{
    opacity: 0;
    transform: translateY(50px);
}

.footer_sns_lists{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.footer_sns_list{
    width: 50px;
    height: 50px;
    list-style: none;
}

.footer_sns_list a{
    display: block;
    width: 100%;
    height: 100%;
}

.footer_sns_list img{
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}