.BlogCassette{
    display: block;
    width: 330px;
    margin: 0 auto 40px;
    padding: 5px 0;
    border: 1px solid #008080;
    box-shadow: 2px 2px 2px #afeeee;
    border-radius: 5px;
}

.BlogCassette .blog_cassette_link{
    text-decoration: none;
    color: black;
}

.BlogCassette .blog_cassette_title{
    padding: 0 15px;
    font-size: 24px;
    font-weight: bold;
}

.BlogCassette .blog_cassette_attached{
    padding: 5px 25px;
    display: flex;
    align-items: center;
}

.BlogCassette .blog_cassette_icon{
    display: block;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.BlogCassette .blog_cassette_date{
    font-size: 20px;
    margin-right: 20px;
}

.BlogCassette .blog_cassette_category{
    font-size: 18px;
}

.BlogCassette .blog_cassette_image{
    display: block;
    width: 320px;
    height: 240px;
    margin: 0 auto;
}

.BlogCassette .blog_cassette_desc{
    padding: 5px 10px;
    font-size: 20px;
}

@media screen and (min-width: 600px){
    .BlogCassette{
        height: 600px;
    }

    .blog_cassettes{
        display: grid;
        grid-template-columns: repeat(auto-fit, 330px);
        grid-gap: 20px;
        justify-content: center;
    }

    .BlogCassette .blog_cassette_title{
        height: 105px;
    }

    .BlogCassette .blog_cassette_desc{
        height: 220px;
    }
}

@media screen and (min-width: 1000px){
    .blog_cassettes{
        display: block;
    }

    .BlogCassette{
        display: block;
        width: 700px;
        height: 330px;
        padding: 0 5px;
        border: 1px solid #008080;
        box-shadow: 2px 2px 2px #afeeee;
        border-radius: 5px;
    }

    .BlogCassette .blog_cassette_block{
        display: flex;
    }

    .BlogCassette:nth-child(even) .blog_cassette_block {
        display: flex;
        flex-direction: row-reverse;
    }

    .BlogCassette .blog_cassette_image_area{
        display: flex;
        align-items: center;
        width: 350px;
        height: 300px;
        margin: 10px 0;
    }

    .BlogCassette .blog_cassette_image{
        display: block;
        width: 350px;
        height: 260px;
    }
    
    .BlogCassette .blog_cassette_title{
        height: auto;
        padding: 0 15px;
        font-size: 22px;
        font-weight: bold;
    }
    
    .BlogCassette .blog_cassette_attached{
        padding: 5px 25px;
        display: flex;
        align-items: center;
    }
    
    .BlogCassette .blog_cassette_icon{
        display: block;
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
    
    .BlogCassette .blog_cassette_date{
        font-size: 20px;
        margin-right: 20px;
    }
    
    .BlogCassette .blog_cassette_category{
        font-size: 18px;
    }
    
    .BlogCassette .blog_cassette_desc{
        height: 220px;
        padding: 5px;
        font-size: 18px;
    }
}
